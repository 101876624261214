import React, { useEffect, useState } from "react";
import Loading from "./components/Loading";
import ReactPlayer from "react-player";
import ErrorMessage from "./components/Error";
import moment from "moment";
import Program from "./components/Program";
import "./App.css";

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const s_id = queryParams.get("station");
  const p_id = queryParams.get("program");
  const name = queryParams.get("name");
  const highlight = queryParams.get("episode");
  const url_base = queryParams.get("url_base");
  const show_station = queryParams.get("show_station");
  const [station, setStation] = useState(null);
  const [program, setProgram] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [track, setTrack] = useState(
    "https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3"
  );
  const [streamProgress, setStreamProgress] = useState(0);
  const [streamPercent, setStreamPercent] = useState(0);
  const [duration, setDuration] = useState(0);
  const [buffering, setBuffering] = useState(false);
  const [length, setLength] = useState(0);
  const [player, setPlayer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [init, setInit] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1);
  const [featured, setFeatured] = useState(null);
  const [loadingHightlighted, setLoadingHighlighted] = useState(false);
  const hasHighlighted = highlight ? highlight : null;

  const shareUrl = url_base ? url_base : null;

  const playAudio = (e) => {
    setPlaying(!playing);
  };

  const newSeek = (percent) => {
    player.seekTo(percent / 100, "fraction");
  };

  const updateProgress = (progress) => {
    var per = (progress.playedSeconds / length) * 100;
    setStreamPercent(Math.floor(per));
    setStreamProgress(Math.floor(Math.floor(progress.playedSeconds)));
  };

  const updateTime = (event, div) => {
    // console.log(event.nativeEvent.offsetX);
    const thisDiv = document.getElementById(div);
    const width = window.getComputedStyle(thisDiv);
    const currentWidth = parseInt(width["width"]);
    var test = (event.nativeEvent.offsetX - thisDiv.offsetLeft) / currentWidth;
    player.seekTo(test);
  };

  const updateDuration = (newDuration) => {
    setLength(Math.floor(newDuration));
    setDuration(
      moment("2015-01-01")
        .startOf("day")
        .seconds(Math.floor(newDuration))
        .format("H:mm:ss")
    );
  };

  // const updateSeek = (time) => {
  //   var diff = streamProgress + time;
  //   player.seekTo(diff > 0 ? diff : 0, "seconds");
  // };

  let ref = (player) => {
    setPlayer(player);
  };

  const sendPostMessage = () => {
    let height;
    setTimeout(function () {
      height = document.getElementById("mrprograms").offsetHeight;
      // console.log("sedning message");
      window.parent.postMessage(
        {
          frameHeight: height,
          eventName: "mrprg",
        },
        "*"
      );
    }, 100);
  };

  useEffect(() => {
    const getEpisodes = async () => {
      try {
        setLoading(true);
        var req = await fetch(
          `${process.env.REACT_APP_API}/public/station/${s_id}/program/${p_id}?showEpisodes=true&showSections=true&showSlots=true`
        );
        const results = await req.json();
        if (results.success === false) {
          setError(results.message);
        } else {
          if (results.data) {
            // console.log("Here", results.data);
            const temp = episodes.concat(results.data.episodes);
            setProgram(results.data);
            setEpisodes(temp);
            setLoading(false);
            if (temp.length === 0) {
              setError("An Error has Occurred");
            } else {
              if (show_station !== null && station === null)
                setStation(temp[0].station);
            }
            sendPostMessage();
          }
        }
      } catch (error) {
        setLoading(false);
        setError("Oops, something went wrong here");
        console.error("here", error);
      }
    };

    if (!s_id || !p_id) {
      setError("There was a proble finding this station and program");
    } else {
      getEpisodes();
    }
  }, [s_id, p_id, page]);

  useEffect(() => {
    const getHighlights = async () => {
      if (featured === null) {
        try {
          setLoadingHighlighted(true);
          var req = await fetch(
            `${process.env.REACT_APP_API}/public/station/${s_id}/episode/${highlight}`
          );
          const results = await req.json();
          if (results.success === false) {
            setError(results.message);
          } else {
            if (results.data) {
              // console.log("This", results.data);
              setFeatured(results.data);
              setLoadingHighlighted(false);
              sendPostMessage();
            }
          }
        } catch (error) {
          setLoadingHighlighted(false);
          setError(
            "Oops, something went wrong loading the linked episode here"
          );
          console.error("here", error);
        }
      }
    };

    if (hasHighlighted) {
      getHighlights();
    }
  }, []);

  useEffect(() => {
    const toggle = async () => {
      if (init === false) {
        await setInit(true);
        await setPlaying(true);
      } else {
        await setPlaying(true);
      }
    };
    toggle();
    sendPostMessage();
  }, [track]);

  return (
    <div className="max-w-7xl mx-auto" id="mrprograms">
      {track && (
        <ReactPlayer
          ref={ref}
          progressInterval={50}
          onDuration={(progress) => updateDuration(progress)}
          // onPlay={(item) => console.log("here", item)}
          playing={playing}
          onProgress={(progress) => updateProgress(progress)}
          controls={false}
          onBuffer={() => setBuffering(true)}
          onBufferEnd={() => setBuffering(false)}
          onEnded={() => setPlaying(false)}
          height={0}
          width={0}
          url={track}
          config={{
            file: {
              forceAudio: true,
            },
          }}
        />
      )}

      {error && <ErrorMessage error={error} />}
      {program && (
        <Program
          featured={featured}
          hasHighlighted={hasHighlighted}
          shareUrl={shareUrl}
          name={name}
          program={program}
          updateTime={updateTime}
          newSeek={newSeek}
          buffering={buffering}
          setTrack={setTrack}
          playing={playing}
          track={track}
          playAudio={playAudio}
          streamProgress={streamProgress}
          streamPercent={streamPercent}
          duration={duration}
          sendPostMessage={sendPostMessage}
          config={{
            file: {
              forceAudio: true,
            },
          }}
        />
      )}

      {loading && <Loading />}

      <div className="w-full w-100 text-center mb-8 px-4">
        <p className="text-center text-sm text-gray-600">
          Built by <a href="https://nicklarosa.net">Nick La Rosa</a> for{" "}
          <a href="https://nicklarosa.net">https://myradio.click</a>
        </p>
      </div>
    </div>
  );
}

export default App;
