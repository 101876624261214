import SectionLinks from "./SectionLinks";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import EpisodeRow from "./EpisodeRow";
import Section from ".//Section";
import { useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";

export default function Program({
  sendPostMessage,
  program = null,
  newSeek,
  updateTime,
  streamProgress,
  streamPercent,
  duration,
  track,
  setTrack,
  playing,
  playAudio,
  buffering,
  name,
  shareUrl = null,
  hasHighlighted = false,
  featured = null,
}) {
  const links = program.links ? JSON.parse(program.links) : [];
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    sendPostMessage();
  }, [tabIndex, sendPostMessage]);

  return (
    <div>
      {!name && program && program.name && (
        <h1 className="page-heading font-bold text-2xl md:text-4xl lg:text-5xl mb-8">
          {program.name}
        </h1>
      )}
      {featured && (
        <div className="w-full mb-8">
          <EpisodeRow
            shareUrl={shareUrl}
            index={0}
            episode={{ ...featured, program: program }}
            program={program}
            updateTime={updateTime}
            newSeek={newSeek}
            buffering={buffering}
            setTrack={setTrack}
            playing={playing}
            track={track}
            playAudio={playAudio}
            streamProgress={streamProgress}
            streamPercent={streamPercent}
            duration={duration}
            sendPostMessage={sendPostMessage}
            count={0}
            config={{
              file: {
                forceAudio: true,
              },
            }}
          />
        </div>
      )}
      {program && (
        <div className="w-full gap-4 md:gap-4 mb-8 grid show-meta grid-cols-4">
          <div className="md:col-span-3 col-span-4">
            {program && program.grid_one_liner && (
              <p className="text-lg font-bold mb-2">{program.grid_one_liner}</p>
            )}
            {program && program.language && (
              <p className="text-lg font-bold mb-2">
                Language: {program.language}
              </p>
            )}
            {/* {program && program.introduction && (
              <p className="text-base">{program.introduction}</p>
            )} */}
            {program && program.presenter_string && (
              <p className="text-base">{program.presenter_string}</p>
            )}
            {links && <SectionLinks links={links} />}
            <div className="bg-white">
              {program &&
                program.description &&
                program.introduction !== program.description && (
                  <div
                    itemProp="description"
                    className=" mt-3 mb-3 html-content"
                    dangerouslySetInnerHTML={{
                      __html: program.description,
                    }}
                  ></div>
                )}
            </div>

            <div className="bg-white">
              <h3 className="text-xl font-bold mb-4">Recent Episodes</h3>
              <div className="border border-gray-200 rounded-md px-2">
                {program &&
                  program.episodes &&
                  program.episodes.map((episode, i) => (
                    <EpisodeRow
                      shareUrl={shareUrl}
                      index={i}
                      count={program.episodes.length - 1}
                      episode={{ ...episode, program: program }}
                      program={program}
                      key={`${i}e`}
                      updateTime={updateTime}
                      newSeek={newSeek}
                      buffering={buffering}
                      setTrack={setTrack}
                      playing={playing}
                      track={track}
                      playAudio={playAudio}
                      streamProgress={streamProgress}
                      streamPercent={streamPercent}
                      duration={duration}
                      sendPostMessage={sendPostMessage}
                      config={{
                        file: {
                          forceAudio: true,
                        },
                      }}
                    />
                  ))}
              </div>
            </div>
            {program && program.sections && program.sections.length > 0 && (
              <div className=" bg-white">
                <h3 className="text-lg font-bold mb-8">Featured Content</h3>
                {program &&
                  program.sections &&
                  program.sections.map((section, i) => (
                    <Section
                      section={{ ...section, program: program }}
                      updateTime={updateTime}
                      newSeek={newSeek}
                      buffering={buffering}
                      setTrack={setTrack}
                      playing={playing}
                      track={track}
                      index={i}
                      playAudio={playAudio}
                      streamProgress={streamProgress}
                      streamPercent={streamPercent}
                      duration={duration}
                      config={{
                        file: {
                          forceAudio: true,
                        },
                      }}
                      key={`${i}s`}
                    />
                  ))}
              </div>
            )}
          </div>
          {program.image && program.image.webp && (
            <div className="hidden md:block md:col-span-1 col-span-4 mb-4 ">
              <img
                src={program.image.webp.tile}
                alt={program.name}
                className={"w-full h-auto aspect-square sticky top-0"}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
